<script setup lang="ts">
import { storeToRefs } from 'pinia';
import MainLayout from '~/components/layout/MainLayout.vue';
import { useMainStore } from '~/stores/mainStore';
import UnsupportedDevice from '~/components/emptyStates/UnsupportedDevice.vue';
const store = useMainStore();
const { page } = storeToRefs(store);

const { defaultClasses } = useLayout();
</script>

<template>
  <MainLayout>
    <template #page>
      <UnsupportedDevice />

      <main
        ref="page"
        class="flex h-full w-screen flex-col"
        :class="defaultClasses"
        data-cy="default-main"
      >
        <slot></slot>
      </main>
    </template>
  </MainLayout>
</template>
